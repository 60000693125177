body {
  margin: 0;
  font-family: myfont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: myfont;
  src: url(../src/Font/Poppins/Poppins-Regular.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
