.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a {
  color: black;
}

h3 {
  font-size: 1.75rem;
  font-weight: 900;
  color: darkcyan;
}

.contact {
  display: grid;
  grid-template-rows: repeat(15, 26vh);
  grid-template-columns: 1fr;
}

.table1_li {
  text-align: start;
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}

.head1 {
  background-color: darkcyan;
  border-radius: 0 0 6rem 6rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 8fr 2fr;
  align-items: center;
  padding: 0rem 1rem;
}

.book_nav {
  height: 10rem;
  width: 100vw;
}

.contact_nav {
  grid-row: 1/3;
  grid-column: 1/2;
}

.Main_Page {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-row: 6/10;
  grid-column: 1/2;
}

.Main_inner1 {
  padding: 3.5rem 5rem 5rem;
  grid-column: 1/2;
  grid-row: 1/2;
}

.Main_inner2 {
  grid-row: 1/2;
  grid-column: 1/2;
  position: relative;
  z-index: -1;
}

.Main_inner2-img {
  height: 50vw;
  opacity: 0.1;
  /* right: -240px; */
  top: 6rem;
  position: relative;
}
/* .mib {
  overflow: hidden; 
}*/

.Main_inner2-imgBg {
  height: 60vw;
  opacity: 0.1;
  position: initial;
  /* right: -300px;
  top: 175px; */
}

#eagle {
  width: 175px;
  height: 100px;
  justify-self: start;
  align-self: flex-start;
}

#logo {
  width: auto;
  height: 123px;
  justify-self: end;
  /* margin-top: 13px; */
  align-self: baseline;
}

.headerContent {
  position: relative;
  bottom: 100px;
}

.bom_nrth {
  font-size: 17px;
  font-weight: 600;
  color: white;
  padding-top: 1rem;
}

.book_pros {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
}

.hindi_text1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: darkcyan;
}

.label_txt {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}

.book_grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2fr 5fr 2fr;
  align-items: center;
}

#book1 {
  width: 250px;
  height: 130px;
  justify-self: start;
}

#book2 {
  width: 230px;
  height: 150px;
  justify-self: end;
}

.start_proj {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: -1px;
  color: black;
}

.book_pros2 {
  font-weight: 700;
  color: darkcyan;
  margin-bottom: 1rem;
}

.table1 {
  padding: 0 25%;
  display: flex;
  flex-wrap: nowrap;
}

.query {
  font-size: 1.75rem;
  font-weight: bold;
  color: black;
}

.click {
  font-weight: 700;
  color: darkcyan;
}

.imageContainer img {
  height: 330px;
  width: 300px;
}
#img3 {
  display: inline-block;
  border-radius: 10px;
  z-index: 999999;
  transform: rotate(0deg);
  border: 10px solid #e9ecef;
  position: relative;
  top: -40px;
}
#img1 {
  display: inline-block;
  border-radius: 10px;
  margin-left: -10px;
  transform: rotate(-10deg);
  margin-right: -77px;
  border: 10px solid #e9ecef;
}
#img2 {
  display: inline-block;
  border-radius: 10px;
  margin-left: -10px;
  transform: rotate(10deg);
  margin-left: -77px;
  border: 10px solid #e9ecef;
}
.imageContainer {
  grid-row: 2/4;
  grid-column: 1/2;
}
.head2 {
  grid-row: 4/5;
  padding-top: 2rem;
}
.head2_coll {
  font-size: 3rem;
  font-weight: 900;
  color: black;
  text-shadow: 2px 4px 2px grey;
  transform: scale(1.3, 1.3);
}

.table2 {
  padding: 2% 12% 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(12, 1fr);
  grid-auto-flow: column;
  grid-row: 5/15;
  grid-column: 1/2;
  padding-top: 5rem;
}
.table3 {
  padding: 0 12%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
}
.table2_ul {
  list-style-type: none;
  text-align: start;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: black;
}
.mob {
  color: darkcyan;
}
/* .part {
  justify-content: center;
    display: grid;
} */
.lead {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: -1px;
}
.lead_name {
  font-size: 1.75rem;
  font-weight: 700;
  color: darkcyan;
}
.lead-div {
  grid-row: 15/-1;
  grid-column: 1/3;
  margin-top: 1rem;
}
.row {
  margin-right: 0;
  margin-left: 0;
}

@media only screen and (max-width: 480px) {
  .table1_li {
    /* text-align: start; */
    font-size: 15px;
    /* font-weight: 600; */
    /* color: black; */
  }
  ul {
    padding-inline-start: 20px;
  }
  .head1 {
    background-color: darkcyan;
    border-radius: 0 0 3.5rem 3.5rem;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: flex-start;
    padding: 5px;
  }
  .book_nav {
    height: 8rem;
  }

  .contact_nav {
    height: 15rem;
  }

  .Main_Page {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  .Main_inner1 {
    padding: 15px 3px 15px;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .Main_inner2 {
    grid-row: 1/2;
    grid-column: 1/2;
    position: relative;
    z-index: -1;
  }

  .Main_inner2-img {
    /* height: 135vw; */
    /* right: -110px; */
    top: 5rem;
    /* position: absolute; */
  }
  .mib {
    overflow: hidden;
  }
  .mic {
    top: -15em;
  }
  #eagle {
    width: 110px;
    height: auto;
    justify-self: start;
    /* margin-top: 10px; */
    align-self: flex-start;
  }

  #logo {
    width: auto;
    height: 92px;
    justify-self: center;
    margin-top: -5px;
    align-self: flex-start;
  }

  .headerContent {
    position: relative;
    bottom: -15px;
  }

  .bom_nrth {
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 1;
    padding-top: 1px;
    margin-bottom: 5px;
  }

  .book_pros {
    font-size: 18px;
    font-weight: 700;
    color: white;
  }

  .hindi_text1 {
    font-size: 17px;
    font-weight: 700;
    color: darkcyan;
  }

  .label_txt {
    font-size: 13px;
    font-weight: 600;
    color: black;
    margin-bottom: -1px;
    line-height: 1.3;
  }

  .book_grid {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
  }

  #book1 {
    width: 115px;
    height: 90px;
    justify-self: start;
  }

  #book2 {
    width: 115px;
    height: 100px;
    justify-self: end;
  }

  .start_proj {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: -1px;
    color: black;
  }

  .book_pros2 {
    font-size: 19px;
    font-weight: 700;
    color: darkcyan;
    margin-bottom: -1px;
  }

  .table1 {
    padding: 0;
  }

  .query {
    font-size: 19px;
    font-weight: bold;
    color: black;
  }

  .click {
    font-weight: 700;
  }

  .imageContainer img {
    height: 150px;
    width: 140px;
  }
  #img3 {
    display: inline-block;
    border-radius: 10px;
    z-index: 999999;
    transform: rotate(0deg);
    border: 6px solid #e9ecef;
    position: relative;
    top: -20px;
  }
  #img1 {
    display: inline-block;
    border-radius: 10px;
    margin-left: 0;
    transform: rotate(-10deg);
    margin-right: -67px;
    border: 6px solid #e9ecef;
  }
  #img2 {
    display: inline-block;
    border-radius: 10px;
    margin-left: -10px;
    transform: rotate(10deg);
    margin-left: -44px;
    border: 6px solid #e9ecef;
  }
  /* .imageContainer {
    position: relative;
    top: -330px;
  } */
  .head2 {
    grid-column: 1/2;
    grid-row: 3/4;
    padding-top: 1rem;
  }
  .head2_coll {
    font-size: 19px;
    font-weight: 900;
    color: black;
    text-shadow: 1px 2px 2px grey;
    transform: scale(1.3, 1.3);
  }
  .contact {
    grid-template-rows: repeat(10, 23vh);
  }
  .table2 {
    padding: 2% 1% 0;
    display: grid;
    grid-auto-flow: column;
    grid-row: 4/13;
    grid-template-rows: repeat(13, 100px);
    margin-top: -3rem;
  }
  .table3 {
    padding: 0 1%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
  }
  .table2_ul {
    list-style-type: none;
    text-align: start;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 1rem;
    color: black;
  }
  .mob {
    color: darkcyan;
  }
  /* .part {
    justify-content: center;
      display: grid;
  } */
  .lead {
    font-size: 16px;
    font-weight: 700;
  }
  .lead_name {
    font-size: 16px;
    font-weight: 700;
    color: darkcyan;
  }
  h3 {
    font-size: 14px;
  }
  .lead-div {
    grid-row: 13/-1;
    grid-column: 1/3;
    /* margin-top: -5rem; */
  }
}
